import { useRouter } from "next/router";
import React from "react";
import { Button } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import Routes from "../../routes";
import { analytics } from "../../services/analytics-api";

interface Props {
  size?: "small" | "medium" | "large";
}

const DownloadButton: React.FC<Props> = ({ size = "medium" }) => {
  const router = useRouter();
  const startDownload = async () => {
    analytics.gtmSend({
      id: analytics.Events.DOWNLOAD_BUTTON_CLICKED,
    });

    router.push(Routes.Download);
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size={size}
        onClick={startDownload}
        startIcon={<GetApp />}
      >
        download launcher
      </Button>
    </div>
  );
};

export default DownloadButton;
