import axios from "axios";
import { merge } from "lodash";
import config from "../../config";
import { getSession } from "next-auth/react";

export const { apiUrl } = config;

// passes in the auth token when available
axios.interceptors.request.use(async (config) => {
  const session = await getSession();

  if (!session?.accessToken) return config;

  return merge(config, {
    headers: {
      Authorization: `Bearer ${session?.accessToken}`,
    },
  });
});

export default axios;
