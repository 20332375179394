import * as Sentry from "@sentry/react";
import config from "../config";
import { windowHelpers } from "../helpers/window-helpers";
import { merge } from "lodash";
import type { CookiesPreferences } from "src/components/cookies/useCookiePreferences";

const { enableLogRocket } = config;

interface AnalyticsEvent {
  id: Events;
  data?: any;
}

enum Events {
  PAGE_VIEW = "pageView",

  CREATOR_CREW_OPEN_MODAL = "openCreatorApplyModal",
  CREATOR_CREW_APPLICATION_COMPLETE = "creatorApplied",
  DOWNLOAD_BUTTON_CLICKED = "downloadButtonClicked",
  DOWNLOAD_CTA_CLICKED = "downloadCtaClicked",
  DOWNLOAD_STARTED = "downloadStarted",
  ENTER_ADDRESS = "enterAddress",
  FORM_FIELD_ERROR = "formFieldError",
  FORM_FIELD_ERROR_CLEARED = "formFieldErrorCleared",
  FORM_FIELD_TOUCHED = "formFieldTouched",
  LOGIN_CLICKED = "loginClicked",
  MARKETPLACE_EMAIL_SIGNUP = "marketplaceEmailSignup",
  PAYMENT_CANCELLED = "paymentCancelled",
  PAYMENT_ERROR = "paymentError",
  PAYMENT_METHOD_REMOVED = "paymentMethodRemoved",
  PAYMENT_OPTION_SELECTED = "paymentOptionSelected",
  PAYMENT_TOKENIZED = "paymentTokenized",
  PURCHASE = "purchase",
  PURCHASE_DISCLAIMER_CHECKED = "purchaseDisclaimerChecked",
  REGISTER_MODAL_ABANDON = "registerModalAbandon",
  REGISTER_MODAL_OPEN = "registerModalOpen",
  REGISTER_MODAL_SUCCESS = "registerModalSuccess",
  SIGNUP_CLICKED = "signupClicked",
  SIGNUP_CREATED = "signupCreated",
  SIGNUP_ERROR = "signupError",
  SIGNUP_STARTED = "signupStarted",
  SHOP_SHARE_ITEM = "shopShareItem",
  SUPPORT_CLICKED = "supportClicked",
  UPDATE_CART = "updateCart",
  VIEW_CHECKOUT_PAYMENT = "viewCheckoutPayment",
  VIEW_DISCLAIMER = "viewDisclaimer",
  FAQ_CLICKED = "faqClicked",
}

const setCookiesPreferences = (
  cookiesPreferences: CookiesPreferences,
  rehydration = false,
) => {
  const data: { cookieSettings: CookiesPreferences; event?: string } = {
    cookieSettings: cookiesPreferences,
  };

  if (!rehydration) {
    data.event = "setCookiesPreferences";
  }

  gtmPushData(data);
};

const setUser = (userId: string, email?: string | null) => {
  if (windowHelpers.isInBrowser()) {
    gtmPushData({ mythicalId: userId });

    if (userId) {
      Sentry.setUser(merge({ id: userId }, email != null ? { email } : null));

      if (enableLogRocket) {
        // TODO: try to fix this without inline require
        // eslint-disable-next-line
        const LogRocket = require("logrocket");
        LogRocket.identify(userId, {
          email,
        });
      }
    }
  }
};

const gtmPushData = (data: unknown) => {
  if (windowHelpers.isInBrowser()) {
    window.dataLayer?.push?.(data);
  }
};

const gtmSend = (event: AnalyticsEvent) => {
  gtmPushData({ ...event.data, event: event.id });
};

export const analytics = {
  Events,
  setCookiesPreferences,
  setUser,
  gtmPushData,
  gtmSend,
};
